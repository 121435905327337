<template>
  <v-col>
    <card
      class="pa-1 ma-5"
      style="min-height: 520px;"
      outlined
    >
      <v-card-title />
      <v-alert
        text
        close-icon="fa fa-close"
        icon="fa4 fa-bar-chart"
        dense
        class="mt-4 py-1"
        color="red"
      >
        共有<strong> {{ projectTotal }} </strong>个信息
      </v-alert>
      <v-simple-table>
        <thead>
          <tr>
            <th>信息名称</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>{{ project.name }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                @click="see(type,project)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions v-if="show">
        <v-btn
          block
          color="grey darken-1"
          outlined
          :to="allList()"
        >
          全部信息...
        </v-btn>
      </v-card-actions>
    </card>
  </v-col>
</template>

<script>
  export default {
    props: {
      formId: {
        type: String,
        // 默认全部
        default: '',
      },
      _from: {
        type: String,
        default: 'org',
      },
      type: {
        type: String,
        default: 'report',
      },
      status: {
        type: Number,
        default: -5,
      },
      title: {
        type: String,
        default: '项目',
      },
      tzcHandle: {
        type: String,
        default: '',
      },
      kfqHandle: {
        type: String,
        default: '',
      },
      tzcfzrHandle: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        // 待审核
        show: true,
        projectList: [],
        projectTotal: 0,
        formIds: '',
      }
    },
    watch: {
      tzcHandle (val) {
        this.getMyProject()
      },
      kfqHandle (val) {
        this.getMyProject()
      },
      tzcfzrHandle (val) {
        this.getMyProject()
      },
      title (val) {
        console.log('this.title', val, this.title);
      },
    },
    async mounted () {
      setTimeout(async () => {
        await this.getMyProject()
      }, 500);
    },
    methods: {
      see (type, project) { // 查看项目
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: project.formId,
            id: project.id,
          },
        })
      },
      getformIds () {
        if (JSON.parse(localStorage.newForms).length) {
          const list = JSON.parse(localStorage.newForms)[0].children
          const formIds = []
          list.map(val => {
            formIds.push(val.id)
          })
          this.formIds = formIds.toString()
        }
      },
      allList () {
        if (this.tzcHandle) {
          return `/examine-list2/?status=${this.status}&formId=${this.formId}&tzcHandle=${this.tzcHandle}&title=${this.title}`
        } else if (this.kfqHandle) {
          return `/examine-list2/?status=${this.status}&formId=${this.formId}&kfqHandle=${this.kfqHandle}&title=${this.title}`
        } else if (this.tzcfzrHandle) {
          return `/examine-list2/?status=${this.status}&formId=${this.formId}&tzcfzrHandle=${this.tzcfzrHandle}&title=${this.title}`
        } else {
          return `/examine-list2/?status=${this.status}&formId=${this.formId}&title=${this.title}`
        }
      },
      // 获取自己提报的项目
      getMyProject () {
        var searchParame = { formId: this.formId, page: 0, size: 5, status: Number(this.status), _from: 'org' }
        if (this.tzcHandle) {
          searchParame.tzcHandle = this.tzcHandle
        } else if (this.kfqHandle) {
          searchParame.kfqHandle = this.kfqHandle
        } else if (this.tzcfzrHandle) {
          searchParame.tzcfzrHandle = this.tzcfzrHandle
        }
        searchParame = { ...searchParame }
        console.log('searchParame', searchParame);

        this.$axios.get('/reports/query', {
          params: searchParame,
        })
          .then(res => {
            const resData = res.data.data
            this.projectList = resData.content
            this.projectTotal = resData.totalElements
            if (!res.data.data.totalElements) {
              this.show = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
